import React, { createContext, useState, useEffect } from 'react';
import commonApi from '../utils/api';
import { useNavigate, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';

export const CommonContext = createContext();

export function CommonContextProvider({ children }) {
    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [merchant, setMerchant] = useState([]);
    const [merchantDetails, setMerchantDetails] = useState([]);
    const [wareHouse, setWareHouse] = useState([]);
    const [permission, setPermission] = useState({});
    const [userTlist, setUserTlist] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [alertMesaage, setAlertMesaage] = useState('');
    const [globalSearch, setGlobalSearch] = useState('');
    const [severity, setSeverity] = useState('');
    const [user, setUser] = useState(null);
    const [userPermission, setUserPermission] = useState(null);
    const [deliveryBoy, setDeliveryBoy] = useState([]);
    const [pickupBoy, setPickupBoy] = useState([]);
    const orderEventsData = localStorage.getItem('ordersEvent');
    const [orderEvents, setOrderEvents] = useState();
    const [courierList, setCourierList] = useState([]);
    const [merchantCourier, setMerchantCourier] = useState([]);
    useEffect(() => {
        setOrderEvents(JSON.parse(orderEventsData))
    }, [orderEventsData]);

    const getOrderStatus = (eventCode) => {
        const event = orderEvents?.find(e => e?.event_code === eventCode?.toString());
        return event ? event.event_name : 'Unknown';
      };

    // Decrypt function
    const decryptData = (encryptedData, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    };

    const secretKey = 'app-secret'; // Replace this with your actual secret key

    const navigate = useNavigate();
    const location = useLocation();
    const onCloseAlert = () => {
        setOpenAlert(false);
        setAlertMesaage('');
        setSeverity('')
    };
    const handleSearchSubmit = (onLoadApi) => {
        navigate('/orders')
        if (location.pathname === '/orders') {
            setIsSearch(true);
        }
    };
    const storedUserData = localStorage.getItem('userApp');

    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const commonMerchant = () => {
        commonApi.post('merchant/listForDropDown')
            .then((response) => {
                if (response?.data?.data) {
                    setMerchant(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    const courierData = () => {
        setIsLoading(true);
        commonApi.post('courier/listForDropDown')
            .then((response) => {
                setIsLoading(false);
                if (response?.data?.s === 1) {
                    setCourierList(response?.data?.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    }
    const allPermissions = (id) => {
        commonApi.post('users/permissions', { admin_id: id })
            .then((response) => {
                if (response?.data?.data) {
                    setPermission(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    const commonUserType = () => {
        commonApi.post('/users/UserTypelistForDropDown')
            .then((response) => {
                if (response?.data) {
                    setUserTlist(response?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    const onDeliveryBoy = () => {
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq = { merchant_id: user?.id }
        } else {
            postReq = { admin_id: user?.id }
        }
        commonApi.post('/users/DeliveryBoylistForDropDown', postReq)
            .then((response) => {
                if (response?.data?.s === 1) {
                    setDeliveryBoy(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    const onPickupBoy = () => {
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq = { merchant_id: user?.id }
        } else {
            postReq = { admin_id: user?.id }
        }
        commonApi.post('/users/PickupBoylistForDropDown', postReq)
            .then((response) => {
                if (response?.data?.s === 1) {
                    setPickupBoy(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    const commonWareHouse = () => {
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq = { merchant_id: user?.id }
        }
        commonApi.post('merchant-warehouse/listForDropDown', postReq)
            .then((response) => {
                if (response?.data?.data) {
                    setWareHouse(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    const onMerchantDetails = (id) => {
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq = { merchant_id: user?.id }
        } else {
            postReq = { merchant_id: id }
        }
        commonApi.post('merchant/merchant-details', postReq)
            .then((response) => {
                if (response?.data?.data) {
                    setMerchantDetails(response?.data?.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    console.log('location',location)
    const onMerchantCourier = () => {
        setFirstLoading(false);
        const postReq = {  }
        if(location?.state){
            postReq.merchant_id= location?.state?.id
        }else{
            postReq.merchant_id= user?.id
        }
        commonApi.post('/merchant/get-my-couriers', postReq)
            .then((response) => {
                setFirstLoading(true);
                console.log('Got response:', response)
                if (response?.data?.data) {
                    setMerchantCourier(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        if (user) {
            commonWareHouse();
            courierData();
        }
    }, [user])
    useEffect(() => {
        if (storedUserData) {
            // const parsedUser = JSON.parse(storedUserData);
            const parsedUser = decryptData(storedUserData, secretKey);
            if (parsedUser?.permissions) {
                setUserPermission(JSON.parse(parsedUser?.permissions))
            }
            setUser(parsedUser);
            if (parsedUser?.user_type !== 'Merchant') {
                commonMerchant();
                allPermissions(parsedUser?.id);
                commonUserType();
                onPickupBoy();
                onDeliveryBoy();
            }
            // if (parsedUser?.user_type === 'Merchant' && location.pathname === '/') {
            //     navigate('/');
            // }
        }
        if (!storedUserData && location.pathname === '/admin') {
            navigate('/admin');
        } else if (!storedUserData) {
            navigate('/login');
        }
    }, [storedUserData]);
    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        firstLoading,
        onMerchantCourier,
        merchantCourier,
        setCourierList,
        courierList,
        getOrderStatus,
        orderEvents,
        setOrderEvents,
        pickupBoy,
        setPickupBoy,
        deliveryBoy,
        setDeliveryBoy,
        onMerchantDetails,
        isSearch,
        handleSearchSubmit,
        permission,
        userTlist,
        globalSearch,
        user,
        userPermission,
        onCloseAlert,
        severity,
        alertMesaage,
        openAlert,
        commonMerchant,
        wareHouse,
        merchantDetails,
        merchant,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setMerchant,
        setMerchantDetails,
        setWareHouse,
        setSeverity,
        setAlertMesaage,
        setOpenAlert,
        setUser,
        setUserPermission,
        setGlobalSearch,
        setUserTlist,
        setPermission,
        setIsSearch
    };

    // Render the provider with children
    return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
}
