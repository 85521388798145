import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utils/api';
import { CommonContext } from './CommonContext';
import moment from 'moment/moment';

export const DashboardContext = createContext();

export function DashboardContextProvider({ children }) {
    const { user } = useContext(CommonContext);
    const [addModal, setAddModal] = useState(false);
    const [allData, setAllData] = useState({});
    const [allDownload, setAllDownload] = useState([]);
    const [viewData, setViewData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [fStartDate, setFStartDate] = useState(null);
    const [fEndDate, setFEndDate] = useState(null);
    const defaultStartDate = moment().startOf('month');
    const defaultEndDate = moment().endOf('month');
    const defaultDateRange = [defaultStartDate.toDate(), defaultEndDate.toDate()];
    const [fDateRange, setFDateRange] = useState(defaultDateRange);
    const [selectedCourierIds, setSelectedCourierIds] = useState([]);
    const [selectedMerchantIds, setSelectedMerchantIds] = useState([]);

    const handleAddModal = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };

    const onLoadApi = () => {
        setFDateRange(defaultDateRange);
        setSelectedCourierIds([]);
        setSelectedMerchantIds([]);
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq = { merchant_id: user?.id, start_date: defaultStartDate.format('YYYY-MM-DD'), end_date: defaultEndDate.format('YYYY-MM-DD') }
        } else {
            postReq = { start_date: defaultStartDate.format('YYYY-MM-DD'), end_date: defaultEndDate.format('YYYY-MM-DD') }
        }
        setFirstLoading(false);
        commonApi.post('/admin/dashboard', postReq)
            .then((response) => {
                setFirstLoading(true);
                if (response?.data?.data) {
                    // setAllDownload(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setFirstLoading(true);
                console.error('Error fetching data:', error);
            });
    };
    const onFilter = () => {
        setIsLoading(true);
        let postReq = {};
        if (user?.user_type === "Merchant") {
            postReq = { merchant_id: user?.id, start_date: fStartDate ? moment(fStartDate).format('YYYY-MM-DD') : defaultStartDate.format('YYYY-MM-DD'), end_date: fEndDate ? moment(fEndDate).format('YYYY-MM-DD') : defaultEndDate.format('YYYY-MM-DD') }
        } else {
            postReq = { start_date: fStartDate ? moment(fStartDate).format('YYYY-MM-DD') : defaultStartDate.format('YYYY-MM-DD'), end_date: fEndDate ? moment(fEndDate).format('YYYY-MM-DD') : defaultEndDate.format('YYYY-MM-DD') }
        }
        if (selectedCourierIds.length > 0) {
            postReq.courier_id = selectedCourierIds
        }
        if (selectedMerchantIds.length > 0) {
            postReq.merchant_id = selectedMerchantIds
        }
        commonApi.post('/admin/dashboard', postReq)
            .then((response) => {
                setIsLoading(false);
                if (response?.data?.data) {
                    // setAllDownload(response?.data?.data);
                    setAllData(response?.data?.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        onLoadApi();
    }, []);

    // You can provide any values or functions you want to make available to consumers here
    const contextValue = {
        selectedCourierIds,
        selectedMerchantIds,
        setSelectedCourierIds,
        setSelectedMerchantIds,
        onFilter,
        fDateRange,
        fStartDate,
        fEndDate,
        onLoadApi,
        isLoading,
        firstLoading,
        viewData,
        allDownload,
        allData,
        handleAddModal,
        handleCloseModal,
        setAddModal,
        addModal,
        setAllData,
        setAllDownload,
        setViewData,
        setFirstLoading,
        setIsLoading,
        setFEndDate,
        setFStartDate,
        setFDateRange,
    };

    // Render the provider with children
    return <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>;
}
